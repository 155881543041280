<template>
    <div class="section" key="spousalsupport">
        <div class="section-title">
            <div class="author"><img width="50" height="50" src="@/assets/images/rabier.png"></div>
            <h3><span>Schritt 9</span> Wie ist der Ehegattenunterhalt vereinbart?</h3>
            <p>Bitte teilen Sie mit, ob Sie sich auf Unterhalt geeinigt haben</p>
        </div>
        <div class="form-container">
            <div class="form-card">
            <input type="radio" v-model="user.ehegattenunterhalt" name="ehegattenunterhalt" value="ja" id="radio-13">
            <label for="radio-13" v-on:click="incrementChecked()"><span class="label-icon"><img src="@/assets/images/icons8-checked-100.svg"></span><span>Beide verzichten</span></label>
            </div>
            <div class="form-card">
            <input type="radio" v-model="user.ehegattenunterhalt" name="ehegattenunterhalt" value="nein" id="radio-14">
            <label for="radio-14" v-on:click="incrementChecked()"><span class="label-icon"><img src="@/assets/images/icons8-wallet-100.svg"></span><span>Eigenregelung</span></label>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'SpousalSupport',
  computed: mapState({
    user: (state) => state.user,
    checked: (state) => state.checked
  }),
  methods: {
    ...mapMutations([
      'incrementChecked'
    ]),
  }
}
</script>